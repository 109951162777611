import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Shield from "../../assets/icons/shield";
import StrikeOver from "../../assets/icons/strikeOver";
import Button from "../../atoms/Button";
import DropDown from "../../atoms/DropDown";
import Pill from "../../atoms/Pill";
import PriceCard from "../../components/priceCard/PriceCard";
import PriceCardFooter from "../../components/priceCard/PriceCardFooter";
import PriceCardFooterText from "../../components/priceCard/PriceCardFooterText";
import PriceCardHeader from "../../components/priceCard/PriceCardHeader";
import PriceCardInfo from "../../components/priceCard/PriceCardInfo";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { formartNumberAsLocalString } from "../../utils/utils";
import { UserCompletedStep } from "../auth/enum";
import { getSubscriptions } from "../billing/selectors";
import { getProductByInterval } from "../billing/utils";
import { CreditsSubscriptionProps } from "./interface";
import { creditSchema } from "./validation";
import CouponCard from "../../pages/couponCard/CouponCard";

const CreditsSubscription = ({
  selectedPricing,
  confirmSubscriptionLoading,
  onSubmit,
  setSelectedPricing,
  creditCount,
  minCreditCount,
  creditAccessProduct,
  footerText = false,
  handlePurchaseModal,
  setCurrentCredits,
  upgradeNow,
  handleCoupon,
  validateButtonLoading,
  couponStatus,
  setCouponStatus,
  updatedPricing,
  handleRemoveCoupon
}: CreditsSubscriptionProps) => {
  const {
    formState: { errors },
    control,
    setValue,
    watch,
    handleSubmit,
  } = useForm({ 
    mode: "onSubmit",
    resolver: yupResolver(creditSchema),
  });
  const [creditOptions, setCreditOptions] = useState<any[]>([]);
  const [applyDiscount, setApplyDiscount] = useState(false);
  // const creditAccessProduct = useSelector((state) =>
  //   getCreditAccessProduct(state)
  // );

  const subscriptions = useSelector((state) => getSubscriptions(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const dispatch = useDispatch();

  // useEffect(()=>{
  //  dispatch(fetchCreditAccessProduct({}));
  // },[])

  useEffect(() => {
    const filteredCreditAccessProduct: any[] = minCreditCount
      ? creditAccessProduct?.filter(
          (product: any) => product.creditCount >= minCreditCount
        )
      : creditAccessProduct;

    const options = filteredCreditAccessProduct.map((product: any) => ({
      value: product.id,
      label: product.productName,
    }));
    setCreditOptions(options ?? []);
    if (creditCount) {
      // const selectedOption = filteredCreditAccessProduct.filter(
      //   (product: any) => product.creditCount > creditCount
      // );
      const selectedOption = minCreditCount
        ? filteredCreditAccessProduct.filter(
            (product: any) => product.creditCount >= minCreditCount
          )
        : filteredCreditAccessProduct.filter(
            (product: any) => product.creditCount > creditCount
          );
      options?.length > 0 &&
        setValue(
          "selectedCreditProduct",
          selectedOption?.length > 0
            ? selectedOption[0].id
            : options[options?.length - 1].value
        );
    } else {
      options?.length > 0 &&
        setValue("selectedCreditProduct", options[0].value);
    }
  }, [creditAccessProduct]);

  useEffect(() => {
    if (watch("selectedCreditProduct")) {
      const product = creditAccessProduct.find(
        (product: any) => product.id === watch("selectedCreditProduct")
      );
      const selected = getProductByInterval(
        watch("annualPricing") ?? false,
        product
      );
      setSelectedPricing(selected);
      const currentCredit: string =
        creditAccessProduct
          .filter(
            (product: any) => product.id === watch("selectedCreditProduct")
          )
          .map((product: any) => String(product.creditCount))[0] || "";
      // footerText setCurrentCredits(currentCredit)
      if (upgradeNow) {
        setCurrentCredits(currentCredit);
      }
    }
  }, [watch("annualPricing"), watch("selectedCreditProduct")]);

  useEffect(() => {
    if (
      profileInfo?.organisation?.stepsCompleted > UserCompletedStep.STEP_ONE
    ) {
      setApplyDiscount(true);
    } else {
      setApplyDiscount(false);
    }
  }, [profileInfo]);

  return (
    <PriceCard>
      <PriceCardHeader>
        <div className={`flex justifySpaceBetween alignCenter cardHeader `}>
          <div>
            <h2>
              AI Calling <Pill label={`Billed Monthly`} className="completed" />
            </h2>
            {}
            <div className={` cardSwitch flex alignCenter`}>
              <p>Take your business to new heights.</p>
            </div>
          </div>
          <div className={`cardHeader__right `}>
            <div className="flex">
              <div className={`cardPrice`}>
                <small>$</small>
                {selectedPricing?.unitAmount}
                {/* {selectedPricing?.unitAmount ?? 0} */}
              </div>
            </div>
            <p>per month</p>
          </div>
        </div>
      </PriceCardHeader>
      <PriceCardInfo>
        <div className={`dflex justifySpaceBetween alignCenter cardInfo `}>
          <header className="dflex justifySpaceBetween alignCenter">
            <div>
              <div className="credits">
                <h3>CREDITS </h3>
                <span>/ month</span>{" "}
              </div>
              <p>1 credit = 1 property record</p>
            </div>
            <DropDown
              options={creditOptions ?? []}
              label=""
              control={control}
              name="selectedCreditProduct"
              errors={errors}
              placeholder="Select Credit Product"
              disabled={couponStatus==="Applied"}
            ></DropDown>
          </header>
          <div className="check-list">
            <ul>
              <li>3 call attempts per number</li>
              <li>Intelligent conversations</li>
              <li>2,400 calls per minute</li>
              <li>Detailed KPIs & stats</li>
              <li>Smart lead summary</li>
              <li>Rapid campaign delivery</li>
            </ul>
          </div>
        </div>
        <CouponCard
          loading={validateButtonLoading}
          couponStatus={couponStatus}
          onSubmit={handleCoupon}
          setCouponStatus={setCouponStatus}
          handleRemoveCoupon={handleRemoveCoupon}
          couponSelected ={updatedPricing?.id}
        />
        {/* {applyDiscount && (
            <>
              <div className={`dflex justifySpaceBetween totalRow`}>
                <p>Regular</p>
                <div className={`right `}>
                  <p>
                    {" "}
                    $
                    {formartNumberAsLocalString(
                      +selectedPricing?.unitAmount ?? 0,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </p>
                </div>
              </div>
              <div className={`dflex justifySpaceBetween totalRow`}>
                <p>Savings</p>
                <div className={` right`}>
                  <p>
                    -$
                    {formartNumberAsLocalString(
                      +(selectedPricing?.unitAmount ?? 0) -
                        +(selectedPricing?.actualPrice ?? 0),
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}{" "}
                  </p>
                </div>
              </div>
            </>
          )} */}
        <div className={`flex justifySpaceBetween totalRow borderTop`}>
          <p>Actual Price</p>
          <div className={` right`}>
            <p>${selectedPricing?.actualPrice}</p>
          </div>
        </div>
        {selectedPricing?.taxAmount != 0 && (
          <div className={`flex justifySpaceBetween totalRow`}>
            <p>Tax</p>
            <div className={` right`}>
              <p>${updatedPricing? updatedPricing?.pricing?.breakdown?.taxAmount : selectedPricing?.taxAmount}</p>
            </div>
          </div>
        )}

        {updatedPricing?.pricing?.breakdown?.discountAmount && (
          <div className={`flex justifySpaceBetween totalRow`}>
            <p>Coupon Discount ({updatedPricing?.id}):</p>
            <div className={` right`}>
              <p>${updatedPricing?.pricing?.breakdown?.discountAmount}</p>
            </div>
          </div>
        )}
        <div className={`flex justifySpaceBetween totalRow`}>
          <p>Total due today :</p>
          <div className={` right`}>
            <p>
              <strong>
                $
                {/* {applyDiscount
                    ? formartNumberAsLocalString(
                        +(selectedPricing?.actualPrice ?? 0),
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    :  */}
                {/* {formartNumberAsLocalString(
                    +(selectedPricing?.unitAmount ?? 0),
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )} */}
                {updatedPricing
                  ? formartNumberAsLocalString(
                      +(updatedPricing?.pricing?.breakdown?.finalAmount ?? 0),
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )
                  : formartNumberAsLocalString(
                      +(selectedPricing?.unitAmount ?? 0),
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                {/* }*/}
              </strong>
            </p>
            <p className={` gray`}>
              $
              {formartNumberAsLocalString(+(selectedPricing?.unitAmount ?? 0), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              {" "}
              / {selectedPricing?.interval} after
            </p>
          </div>
        </div>
      </PriceCardInfo>

      <PriceCardFooter>
        {footerText ? (
          <Button
            label={"Confirm subscription and pay now"}
            postfix={<Shield />}
            className="primary animated full xl"
            action={handleSubmit(onSubmit)}
            clicked={confirmSubscriptionLoading}
            disabled={confirmSubscriptionLoading}
          ></Button>
        ) : (
          <Button
            label={"Confirm subscription and pay now"}
            postfix={<Shield />}
            className="primary animated full xl"
            action={handlePurchaseModal}
            clicked={confirmSubscriptionLoading}
            disabled={confirmSubscriptionLoading}
          ></Button>
        )}
      </PriceCardFooter>
      {footerText && (
        <PriceCardFooterText>
          <p>
            This subscription is an <u>extra service</u> and will be billed in
            addition to the General Access plan you chose on the previous page.
          </p>
        </PriceCardFooterText>
      )}
    </PriceCard>
  );
};
export default CreditsSubscription;
