import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userpool from "../userpool";
import { ToastType } from "../molecules/toaster/enum";
import Cookies from "js-cookie";
import { decodetoken } from "../utils/utils";
export const authenticate = (email: string, password: string): any => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userpool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result: any) => {
        resolve(result);
      },
      onFailure: (err) => {
        console.error(err);
        reject(err);
      },
    });
  });
};

export const defaultLogout = () => {
  // const user = userpool.getCurrentUser();
  // user?.signOut();
  localStorage.removeItem("accessToken");
  localStorage.removeItem("rememberMe");
  localStorage.removeItem("loginType");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("upgrade-now");
  // window.Intercom("shutdown");
  if(window?.Tawk_API?.onLoad){
    window?.Tawk_API?.endChat();
  } 
  // localStorage.removeItem(`intercom.intercom-state-${"hadvw7mr"}`);
  // Cookies.remove(`intercom-device-id-${"hadvw7mr"}`, { path: "" });
  // Cookies.remove(`intercom-id-${"hadvw7mr"}`, { path: "" });
  // Cookies.remove(`intercom-session-${"hadvw7mr"}`, { path: "" });
};

export const resetPassword = (email: string, oldPassword: string, newPassword: string) => {
  const user = new CognitoUser({
    Username: email,
    Pool: userpool,
  });
  user.changePassword(oldPassword, newPassword, (err: any, result: any) => {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    }
  });
};

export const confirmPassword = ({ email, code, password, handleShowToast, setOtpEntered, handleClose }: any) => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userpool,
  });
  cognitoUser.confirmPassword(code, password, {
    onFailure(err) {
      handleShowToast("Wrong OTP", ToastType.error);
      setOtpEntered(false);
    },
    onSuccess(res) {
      handleShowToast("Password updated successfully!", ToastType.success);
      handleClose();
    },
  });
};

export const forgotPassword = (email: string) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userpool,
    });

    user.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        resolve(data);
      },
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
      },
    });
  });
};

export const resendConfirmationCode = (email: string) => {
  return new Promise((resolve, reject) => {
    try {
      const user = new CognitoUser({
        Username: email,
        Pool: userpool,
      });
      user.resendConfirmationCode(function (err, result) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        resolve(result);
        console.log("call result: " + result);
      });
    } catch (err: any) {
      console.error(err);
      reject(err);
    }
  });
};

export const verifyViaOtp = ({ email, OTP }: any) => {
  return new Promise((resolve, reject) => {
    try {
      const user = new CognitoUser({
        Username: email,
        Pool: userpool,
      });
      user.confirmRegistration(OTP, true, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          console.log(data);
          resolve(data);
        }
      });
    } catch (err: any) {
      console.error(err);
      reject(err);
    }
  });
};

// cognito to get session

export async function GetCognitoSessionDefault(): Promise<boolean> {
  try {
    const details: any = decodetoken("", true);

    if (!details || !details?.username) {
      throw new Error("No token present");
    }

    const userData = {
      Username: details?.username,
      Pool: userpool,
    };

    const cognitoUser = new CognitoUser(userData);
    const user = userpool.getCurrentUser();

    if (!user) {
      throw new Error("No user found");
    }

    const session = await new Promise<any>((resolve, reject) => {
      user.getSession((error: any, session: any) => {
        if (error) {
          reject(error);
        } else {
          resolve(session);
        }
      });
    });

    const refresh_token = session.getRefreshToken();

    const refreshSession = await new Promise<any>((resolve, reject) => {
      cognitoUser.refreshSession(refresh_token, (refreshErr: any, refreshSession: any) => {
        if (refreshErr) {
          reject(refreshErr);
        } else {
          resolve(refreshSession);
        }
      });
    });

    const accessToken = refreshSession?.getAccessToken().getJwtToken();
    localStorage.setItem("accessToken", accessToken);
    return accessToken;
  } catch (error) {
    throw new Error("Error occurs while generating token");
  }
}
