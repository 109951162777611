import { format as formatDate } from "date-fns";
import { enUS } from "date-fns/locale";
import { useCallback, useEffect, useRef, useState } from "react";
import { Calendar } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import Follow from "../../../../assets/icons/follow";
import TargetIcon from "../../../../assets/icons/targetIcon";
import Button from "../../../../atoms/Button";
import EmptyPlaceholder from "../../../../atoms/EmptyPlacholder";
import Loader from "../../../../atoms/Loader";
import { getSelectedLeadId } from "../../../../organisms/leadsTable/selectors";
import { PropertyDetailsParentIdType } from "../../property.enum";
import { getPropertyDetails } from "../../property.selector";
import { updatePropertyDetails } from "../../property.service";
import styles from "./FollowUpTab.module.scss";
import NotesTab from "../notesTab/NotesTab";
import { setPropertyDetails } from "../../property.reducer";
import { PermissionListEnum } from "../../../../organisms/auth/enum";
import { getProfileInfo } from "../../../../components/profileMenu/selectors";

const FollowUpTab = ({
  type,
  setShowNoteCount,
}: {
  type: PropertyDetailsParentIdType;
  setShowNoteCount: any;
}) => {
  const dispatch = useDispatch();
  const [followUpDate, setFollowUpDate] = useState<Date | undefined>(undefined);
  const [displayCalender, setDisplayCalender] = useState(false);
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const { followUpAt } = propertyDetails;
  const selectedLeadId = useSelector((state) => getSelectedLeadId(state));
  const profileData = useSelector((state) => getProfileInfo(state));
  const [isUpdating, setIsUpdating] = useState(false);
  const showCancel = useRef<boolean>(false);
  useEffect(() => {
    if (
      propertyDetails &&
      Object.keys(propertyDetails).length > 0 &&
      propertyDetails?.followUpAt
    ) {
      setFollowUpDate(new Date(propertyDetails.followUpAt));
    }
  }, [propertyDetails]);

  const handleDateChange = useCallback(
    async () => {
        try {
          setIsUpdating(true);
          await updatePropertyDetails({
            id:
              type === PropertyDetailsParentIdType.LEAD_ID
                ? selectedLeadId
                : propertyDetails?.propertyId,
            payload: { followUpAt: null },
            type,
          });
          const updatedPropertyDetails = {
            ...propertyDetails,
            followUpAt: null,
          };
          dispatch(setPropertyDetails(updatedPropertyDetails));
          setIsUpdating(false);
          setFollowUpDate(undefined);
          showCancel.current = false;
          setDisplayCalender(false);
        } catch (error) {
          setIsUpdating(false);
        } 
    },
    [selectedLeadId, propertyDetails?.propertyId, setFollowUpDate, type]
  );

  const handleCalenderDateUpdate = useCallback(
    async (date: Date | null | undefined)=>{
      if(date) {
        setFollowUpDate(new Date(date));
        showCancel.current= true
      }
  },[setFollowUpDate])
     

  const handleCalenderDateChange = useCallback(
    async (date: Date | null | undefined) => {
      if(date) {
        setFollowUpDate(new Date(date));
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const dateof = ` ${date.getFullYear()}-${m > 9 ? m : "0" + m}-${
          d > 9 ? d : "0" + d
        }`;
        try {
          setIsUpdating(true);
          await updatePropertyDetails({
            id:
              type === PropertyDetailsParentIdType.LEAD_ID
                ? selectedLeadId
                : propertyDetails?.propertyId,
            payload: { followUpAt: dateof ?? null },
            type,
          });
          setIsUpdating(false);
          setDisplayCalender(false);
          const updatedPropertyDetails = {
            ...propertyDetails,
            followUpAt: dateof,
          };
          dispatch(setPropertyDetails(updatedPropertyDetails));
          showCancel.current= false
        } catch (error) {
          setIsUpdating(false);
        }
      }
    },
    [selectedLeadId, propertyDetails?.propertyId, setFollowUpDate, type]
  );
  const hanleButtonClick = () => {
    setDisplayCalender(true);
  };

  return (
    <div className="wrapper">
      <div className={` ${styles.follow} dflex alignCenter`}>
        <div className={` ${styles.follow__Placeholder} `}>
          {!displayCalender ? (
            <EmptyPlaceholder
              icon={<Follow />}
              title={
                !followUpDate ? "Let’s Close That Deal!" : "Follow Up Scheduled"
              }
              description={
                followUpDate
                  ? formatDate(followUpDate, "MMMM dd, yyyy")
                  : "It’s a fact: 75% of deals close after the follow-up. Time to take action and schedule yours now!"
              }
              bgGray={true}
              extraStyles={{
                maxWidth: "450px",
                paddingBottom: 0,
                minHeight: 0,
              }}
              blared
              button1={
                !followUpDate ? (
                  <Button
                    prefix={<TargetIcon />}
                    className="outline borderGradient"
                    label="Set Follow up date"
                    disabled={displayCalender || profileData?.userRoleName ===  PermissionListEnum.ORGANISATION_VIEWER}
                    action={hanleButtonClick}
                  ></Button>
                ) : (
                  <>
                    <Button
                      prefix={<TargetIcon />}
                      className="outline borderGradient"
                      label="Set a New Follow up date"
                      disabled={displayCalender || profileData?.userRoleName ===  PermissionListEnum.ORGANISATION_VIEWER }
                      action={hanleButtonClick}
                    ></Button>
                  </>
                )
              }
              button2={
                !followUpDate ? (
                  <></>
                ) : (
                  <Button
                    className="outline borderGradient"
                    label="Clear"
                    action={handleDateChange}
                    disabled={ profileData?.userRoleName ===  PermissionListEnum.ORGANISATION_VIEWER}
                  ></Button>
                )
              }
            />
          ) : (
            <div
              className={` ${styles.follow__calender} ${
                !displayCalender ? styles.blur : ""
              }`}
            >
              {isUpdating ? (
                <Loader />
              ) : (
                <>
                  <div className="justifyCenter flex">
                    <Calendar
                      months={2}
                      direction="horizontal"
                      locale={enUS}
                      date={followUpDate}
                      onChange={handleCalenderDateUpdate}
                      dateDisplayFormat={"MM/dd/yyyy"}
                      minDate={new Date()}
                    ></Calendar>
                  </div>
                  <div
                    className={` ${styles.follow__calenderBtn} justifyCenter flex alignCenter`}
                  >
                    {showCancel.current && propertyDetails.followUpAt &&
                      <Button
                      className="outline borderGradient"
                      label="Cancel"
                      action={()=>handleCalenderDateChange(new Date(propertyDetails.followUpAt))}
                      disabled={!showCancel.current}
                      ></Button>}
                      
                    {showCancel.current ? (
                      <Button
                        className="primary animated"
                        label="Save"
                        action={()=>handleCalenderDateChange(followUpDate)}
                        ></Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <div className={` ${styles.follow__note}`}>
          <NotesTab setShowNoteCount={setShowNoteCount} />
        </div>
      </div>
    </div>
  );
};
export default FollowUpTab;
