import {
  Autocomplete,
  Box,
  ClickAwayListener,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import DropDownIcon from "../assets/icons/dropDownIcon";
import SearchReflation from "../assets/icons/searchReflation";
import Tick from "../assets/icons/tick";
import { DropDownOption, DropDownProps } from "../models/dropDown.interface";
import EmptyPlaceholder from "./EmptyPlacholder";
const DropDown = ({
  options,
  control,
  label,
  name,
  errors,
  placeholder,
  hint,
  small,
  disabled = false,
  handleOnChange = () => {},
  defaultStartAdornment,
  clearOnBlur,
  dataTestId,
  defaultValue,
  handleOnFocus,
  gradientBorder,
  freeSolo = true,
  smallHeight,
  closeOnOutsideClick = false,
}: DropDownProps) => {
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [openFlag, setOpenFlag] = useState<boolean>(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const elementRef = useRef(null);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLowerCase() === "enter") {
      setOpenFlag(!openFlag);
    }
  };

  useEffect(() => {
    const parentDiv = document.getElementById("parentDiv");
    if (openFlag) {
      document.body.style.overflow = "hidden";
      if (parentDiv) {
        parentDiv.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = "";
      if (parentDiv) {
        parentDiv.style.overflow = "";
      }
    }
    return () => {
      document.body.style.overflow = "";
      if (parentDiv) {
        parentDiv.style.overflow = "";
      }
    };
  }, [openFlag]);

  useEffect(() => {
    setHighlightedIndex(-1);
  }, [options]);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setOpenFlag((prev) => (prev ? false : prev));
        }
      },
      { threshold: 0.05 }
    );
    if (elementRef.current) {
      observerRef.current.observe(elementRef.current);
    }
    return () => {
      if (elementRef.current) {
        observerRef?.current?.unobserve(elementRef.current);
      }
    };
  }, [setOpenFlag]);

  return (
    <div
      className={`inputSelect ${gradientBorder ? `gradientBorder` : ``} ${
        small && `inputSmall`
      } ${errors && errors?.[name] ? "selectError" : ``}`}
      data-testid="inputSelect"
      ref={elementRef}
    >
      {label && <label className="label">{label}</label>}
      <form autoComplete="off">
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <ClickAwayListener
            onClickAway={() => closeOnOutsideClick && setOpenFlag(false)}
          >
            <Autocomplete
              noOptionsText={
                <div className="search-noresult-found ">
                  <EmptyPlaceholder
                    icon={<SearchReflation />}
                    title="No results found"
                    smallSize
                    extraStyles={{ paddingTop: "20px" }}
                  />
                </div>
              }
              data-testid={dataTestId}
              openOnFocus={true}
              open={openFlag}
              onOpen={() => setOpenFlag(true)}
              onClose={() => setOpenFlag(false)}
              disabled={disabled}
              defaultValue={defaultValue}
              PaperComponent={({ children }) => (
                <Paper className={`${smallHeight ? `maxDropdownHeight` : ``}`}>
                  {children}
                </Paper>
              )}
              value={
                options.find((option) => {
                  if (typeof value !== "string") {
                    return (
                      JSON.stringify(option.value) === JSON.stringify(value)
                    );
                  } else {
                    return option.value === value;
                  }
                }) ?? null
              }
              clearOnBlur={clearOnBlur}
              freeSolo={freeSolo}
              onChange={(
                e: React.SyntheticEvent,
                value: DropDownOption | string | null
              ) => {
                onChange((value as DropDownOption)?.value ?? "");
                handleOnChange(value);
              }}
              options={options}
              renderOption={(
                props: any,
                option: DropDownOption,
                state: any
              ) => (
                <>
                  <Box
                    component="li"
                    {...props}
                    className={`multiSelectItem ${
                      state.index === highlightedIndex ? "Mui-focusVisible" : ""
                    }`}
                    key={option?.label?.replace(/ /g, "_") || ""}
                  >
                    <div className="flex optionList alignCenter justifySpaceBetween">
                      <div className="optionList__left">
                        {option.prefix}
                        {option?.bold && <strong>{option?.bold}</strong>}
                        {option.label}
                        {option.pill && option.pill}
                      </div>
                      <div className="flex gap-8 alignCenter">
                        <div className="optionList__right">
                          <Tick />
                        </div>
                        {option?.optionRight}
                      </div>
                    </div>
                  </Box>
                </>
              )}
              renderInput={(params) => {
                const slectedOption = options.find(
                  (option) => option.value === value
                );
                const prefix =
                  slectedOption || defaultStartAdornment ? (
                    slectedOption?.bold || slectedOption?.prefix ? (
                      <>
                        <span className="selectedOptions">
                          {slectedOption.prefix}
                          {slectedOption?.bold && (
                            <strong>{slectedOption?.bold}</strong>
                          )}
                        </span>
                      </>
                    ) : (
                      defaultStartAdornment
                    )
                  ) : (
                    <></>
                  );

                return (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder={placeholder}
                    onFocus={handleOnFocus}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: prefix,
                      endAdornment: (
                        <>
                          <div
                            className="selectValueArrow"
                            onClick={() => setOpenFlag(true)}
                          >
                            {small && (
                              <span className="selectValue">
                                {options &&
                                  value &&
                                  options.map(
                                    (option) =>
                                      option.value === value && option.label
                                  )}
                              </span>
                            )}
                            <div className="selectArrow">
                              {params.InputProps.endAdornment}
                              <DropDownIcon />
                            </div>
                          </div>
                        </>
                      ),
                    }}
                    autoComplete="off"
                  />
                );
              }}
              onKeyDown={handleKeyDown}
            />
          </ClickAwayListener>
        )}
      />
</form>
      {errors && errors?.[name] && (
        <span className="error">{errors?.[name]?.message}</span>
      )}
      {errors && errors?.message && (
        <span className="error">{errors?.message}</span>
      )}
      {hint && <div className="hint">{hint}</div>}
    </div>
  );
};
export default DropDown;
