import Dialog from "@mui/material/Dialog";
import { CreateListProps } from "./interface";
import Close from "../../assets/icons/cross";
import SkipTrace from "./SkipTrace";
import { CreateListSteps } from "./enum";
import { useEffect, useState } from "react";
import SkipTraceSuccess from "./SkipTraceSuccess";
import { useDispatch } from "react-redux";
import { fetchCampaignLeadList } from "../createCampaign/sagaActions";
import { skipProperty } from "./skipTraceService";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { resetCreateListState } from "./reducer";

const getCurrentStep = (
  step: CreateListSteps,
  onSubmit: Function,
  setStep: Function,
  loading: boolean,
  biilingCardError: boolean,
  setBiilingCardError: Function,
  billingCardErrorText: string,
  setBillingCardErrorText: Function,
  setInvalidResultCount?: any,
  invalidResultCount?: boolean,
  skipPropApiFailText?: string,
  setSkipPropApiFailText?: any,
  propertySearchFilter: any = {},
  couponStatus?: string,
  setCouponStatus?: Function,
  updatedPricing: any = {},
  handleRemoveCoupon?:Function,
  setUpdatedPricing?:Function
) => {
  switch (step) {
    case CreateListSteps.SkipTrace: {
      return (
        <SkipTrace
          onSubmit={onSubmit}
          loading={loading}
          biilingCardError={biilingCardError}
          setBiilingCardError={setBiilingCardError}
          billingCardErrorText={billingCardErrorText}
          setBillingCardErrorText={setBillingCardErrorText}
          setInvalidResultCount={setInvalidResultCount}
          invalidResultCount={invalidResultCount}
          skipPropApiFailText={skipPropApiFailText}
          setSkipPropApiFailText={setSkipPropApiFailText}
          propertySearchFilter={propertySearchFilter}
  
          couponStatus={couponStatus} setCouponStatus={ setCouponStatus}
          updatedPricing={updatedPricing}
          handleRemoveCoupon={handleRemoveCoupon}
          setUpdatedPricing={setUpdatedPricing}
        ></SkipTrace>
      );
    }
    case CreateListSteps.SkipTraceSuccess: {
      return <SkipTraceSuccess onSubmit={onSubmit}></SkipTraceSuccess>;
    }
  }
};

const CreateList = ({
  open,
  setOpen,
  propertySearchFilter,
}: CreateListProps) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<CreateListSteps>(CreateListSteps.SkipTrace);
  const [invalidResultCount, setInvalidResultCount] = useState<boolean>(false);
  const [skipPropApiFailText, setSkipPropApiFailText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [biilingCardError, setBiilingCardError] = useState(false);
  const [billingCardErrorText, setBillingCardErrorText] = useState("");
  const [couponStatus, setCouponStatus] = useState("");
  const [updatedPricing, setUpdatedPricing] = useState<any>(null);

    const handleRemoveCoupon=()=>{
      setCouponStatus("");
      setUpdatedPricing(null)
    }

  const closeModal = (event?: any, reason?: string) => {
    setInvalidResultCount(false);
    if (reason && reason === "backdropClick") {
      return;
    }
    setOpen(false);
    dispatch(resetCreateListState({}));
  };

  const handleStepSubmit = (data: any) => {
    switch (step) {
      case CreateListSteps.SkipTrace: {
        handleSkipProperty(data);
        break;
      }
      case CreateListSteps.SkipTraceSuccess: {
        closeModal();
        break;
      }
    }
  };

  const handleSkipProperty = async (data: any) => {
    const payload = {
      ...propertySearchFilter,
      // dontRedoSkip: data?.redoSkip,
      // skipCount: data?.searchResultCount,
      ...(data?.addToExistingList
        ? { listId: data?.targetList }
        : { skip :{
          listName:data?.listName ,
        count:data?.searchResultCount
    }}),
    ...(updatedPricing && { couponCode: updatedPricing.id }),
 // ...(data?.skipPeriod && { period: data?.skipPeriod}),
    };
  
    try {
      setIsLoading(true);
      await skipProperty(payload);
      setStep(CreateListSteps.SkipTraceSuccess);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error?.response?.data?.type === "PAYMENT_FAILED") {
        setBiilingCardError(true);
        setBillingCardErrorText(
          error?.response?.data?.message ??
            `We were unable to process your payment. Your bank has either
                    declined or rejected this charge.`
        );
      } else {
        displayErrorMessage(error);
      }
    }
  };

  const displayErrorMessage = (error: any) => {
    setSkipPropApiFailText(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : error?.message ?? "Something went wrong!"
    );
  };

  useEffect(() => {
    dispatch(fetchCampaignLeadList({ applyfilter: true }));
    return () => {
      dispatch(resetCreateListState({}));
    };
  }, []);

  return (
    <Dialog
      onClose={closeModal}
      open={open}
      className={`stepsDialog ${
        CreateListSteps.SkipTraceSuccess === step ? `successDialog` : ``
      }`}
    >
      <div className="dialogWrapper">
        <span role="link" onClick={() => closeModal()} className="closeDialog">
          <Close />
        </span>
        {getCurrentStep(
          step,
          handleStepSubmit,
          setStep,
          isLoading,
          biilingCardError,
          setBiilingCardError,
          billingCardErrorText,
          setBillingCardErrorText,
          setInvalidResultCount,
          invalidResultCount,
          skipPropApiFailText,
          setSkipPropApiFailText,
          propertySearchFilter,
          couponStatus,
          setCouponStatus,
          updatedPricing,
          handleRemoveCoupon,
          setUpdatedPricing
        )}
      </div>
    </Dialog>
  );
};
export default CreateList;
