import Dialog from "@mui/material/Dialog";
import Close from "../../assets/icons/cross";
import styles from "./UpgradeModal.module.scss";
import ChartBreakOut from "../../assets/icons/chartBreakout";
import Zap from "../../assets/icons/zap";
import MessageChat from "../../assets/icons/messageChat";
import CreditsSubscription from "../creditsSubscription/CreditsSubscription";
import { useEffect, useState } from "react";
import { createSubscription, updatePlan } from "../billing/billingService";
import { useDispatch, useSelector } from "react-redux";
import { addToast, removeToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import {
  fetchCreditAccessProduct,
  fetchCreditUsages,
  fetchInvoices,
  fetchSubscriptions,
} from "../billing/sagaActions";
import { ProductType } from "../billing/enum";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { UpgradeModalParentType } from "./enum";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import { UpgradeModalProps } from "./interface";
import Loader from "../../atoms/Loader";
import { fetchCreditAccessProducts, validateCoupon } from "../creditsSubscription/CreditsSubscriptionService";
import AddPaymentModal from "../billing/AddPaymentModal";
import { getSubscriptions } from "../billing/selectors";
import PurchaseConfirmation from "../../components/PurchaseConfirmation/PurchaseConfirmation";
import { fbc, fbp, fetchIp, gtagEvent, hashNumber, hashString } from "../../utils/utils";
import { FbConversionApi } from "../auth/authServices";
import ReactGA from "react-ga4";
import { GtagEventParams } from "../../models/gtag.interface";

const UpgradeModal = ({
  handleClose,
  open,
  product,
  parentType,
  minCreditCount,
}: UpgradeModalProps) => {
  const dispatch = useDispatch();
  const [selectedPricing, setSelectedPricing] = useState<any>(null);
  const [updatedPricing, setUpdatedPricing] = useState<any>(null);
  const [confirmSubscriptionLoading, setConfirmSubscriptionLoading] =
    useState(false);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [creditPoints, setCreditPoints] = useState(true);
  const [creditAccessProduct, setCreditAccessProduct] = useState([]);
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const subscription = useSelector((state) => getSubscriptions(state));
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [currentCredits, setCurrentCredits] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [validateButtonLoading, setValidateButtonLoading] = useState(false);
  const [couponStatus, setCouponStatus] = useState("");
  const confirmSubscription = async () => {
    const payloadData = {
      data: [
        {
          event_name: "Purchase",
          event_time: Math.floor(Date.now() / 1000),
          event_source_url: window.location.href,
          action_source: "website",
          user_data: {
            client_ip_address: fetchIp,
            client_user_agent: navigator.userAgent,
            fbc: fbc,
            fbp: fbp,
            em: hashString(profileInfo?.email),
            ph: hashNumber(profileInfo?.phone),
            external_id: hashNumber(profileInfo?.organisationId),
            fn:hashString(profileInfo?.nameFirst),
            ln:profileInfo?.nameLast ? hashString(profileInfo?.nameLast) : null,
          },
          custom_data: {
            value: selectedPricing?.unitAmount,
            currency: "USD",
         },

        },
      ],
    };

    const params: GtagEventParams = {
      event_category: "User",
      event_label: "Purchase",
      user_email: profileInfo?.email,
      event_time: Math.floor(Date.now() / 1000),
      event_source_url: window.location.href,
      Id: profileInfo?.organisationId,
      first_name: profileInfo?.nameFirst,
      last_name: profileInfo?.nameLast ? profileInfo?.nameLast : null,
      value: selectedPricing?.unitAmount,
      currency: "USD",
    };

    if (subscription?.creditSubData) {
      try {
        setConfirmSubscriptionLoading(true);
        await updatePlan({
          stripeSubscriptionId: product?.stripeSubscriptionId,
          stripePriceId: selectedPricing.stripePriceId,
          subsProductType: ProductType.CREDIT_ACCESS,
          ...(updatedPricing && { couponCode: updatedPricing.id }),
        });
        // ReactGA.event({
        //   category: 'User',
        //   action: 'Purchase',
        //   value: selectedPricing?.unitAmount,
        // });
        await FbConversionApi(payloadData);
        gtagEvent('event', 'Purchase', params);
        onSuccess();
      } catch (error: any) {
        displayErrorMessage(error);
        setConfirmSubscriptionLoading(false);
        if (error?.response?.data?.errorCode === "card_declined") {
          setOpenAddPaymentModal(true);
        }
      }
    } else {
      try {
        setConfirmSubscriptionLoading(true);
        let payload = {
          paymentMethod: profileInfo?.organisation?.paymentMethodId,
          price: selectedPricing.stripePriceId,
          subsProductType: ProductType.CREDIT_ACCESS,
          trialPeriodDays: selectedPricing.trialPeriodDays, ...(updatedPricing && { couponCode: updatedPricing.id }),
        };
        await createSubscription(payload);
        await FbConversionApi(payloadData);
        onSuccess();
      } catch (error:any) {
        displayErrorMessage(error);
        setConfirmSubscriptionLoading(false);
        if (error?.response?.data?.errorCode === "card_declined") {
          setOpenAddPaymentModal(true);
        }
      }
    }
  };

  const onSuccess = () => {
    setTimeout(() => {
      // handleClose();
      setConfirmSubscriptionLoading(false);
      switch (parentType) {
        case UpgradeModalParentType.Header:
        case UpgradeModalParentType.Billing: {
          dispatch(fetchInvoices({}));
          break;
        }
        case UpgradeModalParentType.Usage: {
          dispatch(fetchCreditUsages({}));
          break;
        }
        case UpgradeModalParentType.AnotherAttempt:
        case UpgradeModalParentType.Camapign: {
          break;
        }
      }
      dispatch(fetchProfileInfo({}));
      setPaymentSuccess(true);
      displaySuccessMessage("Subscription confirmed successfully!");
    }, 3000);
  };

  const displayErrorMessage = (error: any) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  };

  const displaySuccessMessage = (message: string) => {
    const messageId = new Date().getTime();

    dispatch(
      addToast({
        id: messageId,
        message: message,
        type: ToastType.success,
      })
    );

    dispatch(
      removeToast({
        id: messageId,
      })
    );
  };


  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handlePurchaseModal = () => {
    setShowPurchaseModal(!showPurchaseModal);
  };

  const handleCloseModal = () => {
    handleClose();
    setCouponStatus("")
    setPaymentSuccess(false);
    setUpdatedPricing(null)
    setShowPurchaseModal(false);
  };

  const handleCoupon = async (data: any) => {
    try {
      setValidateButtonLoading(true);
      const res = await validateCoupon({
        couponCode: data?.code.toUpperCase(),
        priceId: selectedPricing.stripePriceId,
      });
      setUpdatedPricing(res?.data?.data);
      setValidateButtonLoading(false);
      setCouponStatus("Applied");
    } catch (error:any) {
      setValidateButtonLoading(false);
      setCouponStatus(error?.response?.data?.message);
    }
  };

  const handleRemoveCoupon=()=>{
    setCouponStatus("");
    setUpdatedPricing(null)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const creditAccessProduct: any = await fetchCreditAccessProducts();
        setCreditAccessProduct(creditAccessProduct.data);
        setCreditPoints(false);
      } catch (error: any) {
        handleShowToast(error?.message, ToastType.error);
      }
    };
    fetchData();
  }, []);

  
  useEffect(() => {
    dispatch(fetchSubscriptions({})); 
  }, []);

  return (
    <>
      <Dialog
        onClose={() => {
          if (!paymentSuccess && showPurchaseModal) {
            handlePurchaseModal();
          } else {
            handleCloseModal();
          }
        }}
        open={open}
        className={`stepsDialog upgradeModal ${
          paymentSuccess && showPurchaseModal && "paymentSuccess"
        } ${showPurchaseModal && "showPurchaseModal"} ${
          subscription?.creditSubData?.creditCount && "upgradeModalSmall"
        }`}
        onBackdropClick={() => {
          if (showPurchaseModal) {
            handlePurchaseModal();
          }
        }}
      >
        <span
          role="link"
          onClick={() => {
            if (!paymentSuccess && showPurchaseModal) {
              handlePurchaseModal();
            } else {
              !confirmSubscriptionLoading && handleCloseModal()
            }
          }}
          // !confirmSubscriptionLoading && handleClose()
          // }
          className="closeDialog"
        >
          <Close />
        </span>
        {showPurchaseModal ? (
          <PurchaseConfirmation
            onSubmit={confirmSubscription}
            selectedPricing={selectedPricing}
            currentCredits={currentCredits}
            paymentSuccess={paymentSuccess}
            confirmSubscriptionLoading={confirmSubscriptionLoading}
            handleClose={handleCloseModal}
            vaPlan={false}
            updatedPricing={updatedPricing}
          />
        ) : (
          <>
            {creditPoints ? (
              <div className="loader-main">
                <Loader></Loader>
              </div>
            ) : (
              <div className={` ${styles.upgrade}`}>
                <div className="wrapper-md">
                  <div className={`dflex alignCenter ${styles.upgrade__info}`}>
                    {!subscription?.creditSubData?.creditCount && (
                      <div className={` ${styles.upgradeInfo}`}>
                        <div className={` ${styles.upgradeInfo__header}`}>
                          <h2>AI-powered leads made easy.</h2>
                          <p>
                            Welcome to the future. Let’s upgrade your business.
                          </p>
                        </div>
                        <ul>
                          <li>
                            <figure>
                              <MessageChat />
                            </figure>
                            <figcaption>
                              <h3>
                                Calling powered by artificial intelligence.
                              </h3>
                              <p>
                                Our AI technology allows for natural phrasing
                                and dynamic engagements using our human-like
                                voice models. Our advanced calling algorithms
                                ensure optimal results and quality leads.
                              </p>
                            </figcaption>
                          </li>
                          <li>
                            <figure>
                              <Zap />
                            </figure>
                            <figcaption>
                              <h3>Human vs OttoLeads.</h3>
                              <p>
                                It takes a human on a dialer{" "}
                                <strong>3 years</strong> to do what OttoLeads
                                can do in <strong>one day</strong>. Say goodbye
                                to sitting on a computer all day and dialing for
                                hours. Let us do all the heavy lifting for you
                                while you focus on closing deals.
                              </p>
                            </figcaption>
                          </li>
                          <li>
                            <figure>
                              <ChartBreakOut />
                            </figure>
                            <figcaption>
                              <h3>Scale rapidly and dominate your market.</h3>
                              <p>
                                Scaling a real estate investment business used
                                to take <strong>years</strong>; with OttoLeads,
                                you can scale in <strong>months</strong>. Fill
                                up your pipeline in the click of a button and
                                dominate your market.
                              </p>
                            </figcaption>
                          </li>
                        </ul>
                      </div>
                    )}
                    <div
                      className={` ${styles.upgradeCard} ${
                        subscription?.creditSubData?.creditCount &&
                        styles.upgradeCardFull
                      }`}
                    >
                      <CreditsSubscription
                        selectedPricing={selectedPricing}
                        confirmSubscriptionLoading={confirmSubscriptionLoading}
                        onSubmit={confirmSubscription}
                        setSelectedPricing={setSelectedPricing}
                        creditCount={product?.creditCount}
                        minCreditCount={minCreditCount}
                        creditAccessProduct={creditAccessProduct}
                        handlePurchaseModal={handlePurchaseModal}
                        setCurrentCredits={setCurrentCredits}
                        handleCoupon={handleCoupon}
                        validateButtonLoading={validateButtonLoading}
                        couponStatus={couponStatus} setCouponStatus={ setCouponStatus}
                        updatedPricing={updatedPricing}
                        handleRemoveCoupon={handleRemoveCoupon}
                        upgradeNow
                      ></CreditsSubscription>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Dialog>
      {openAddPaymentModal && (
        <AddPaymentModal
          handleClose={() => {
            setOpenAddPaymentModal(false);
          }}
          open={openAddPaymentModal}
          replacePaymentMethod
        ></AddPaymentModal>
      )}

    </>
  );
};
export default UpgradeModal;
