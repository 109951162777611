import axios from "axios";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Close from "../../assets/icons/cross";
import Logo from "../../components/Logo";
import { Checkbox, FormControlLabel } from "@mui/material";
import { addToast } from "../../molecules/toaster/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginFormSchema } from "./validation";
import { resendConfirmationCode } from "../../services/auth";
import { ToastType } from "../../molecules/toaster/enum";
import { setAuthGuardToken } from "../../organisms/authroute/action";
import { decodetoken, gtagEvent } from "../../utils/utils";
import { GoogleSignIn } from "../../services/cognitoService";
import {
  getProfileInfo,
  getProfileInfoLoading,
} from "../../components/profileMenu/selectors";
import { deviceDetect } from "react-device-detect";
import { getIpAddress } from "../../apis/apis";
import { LOGIN_TYPE } from "../../organisms/auth/enum";
import {
  FbConversionApi,
  fetchProfileApiFn,
  loginUserFn,
  postAuditApiFn,
} from "../../organisms/auth/authServices";
import NotConfirmedModal from "../../organisms/emailNotConfirm/EmailNotConfirm";
import { useNavigate } from "react-router-dom";
import {
  setProfileInfo,
  setProfileInfoLoading,
  setSessionLoading,
} from "../../components/profileMenu/reducer";
import ReactGA from "react-ga4";
import { GtagEventParams } from '../../models/gtag.interface';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
declare global {
  interface Window {
    intercomSettings: any;
  }
}

interface CustomError {
  response?: {
    data?: {
      message?: string;
      isEmailVerified?: boolean;
    };
  };
}

const Login = ({
  open,
  setOpen,
  forgotpassword,
  setEmail,
  email,
  openVerifyOtp,
  saveCalDetail,
  calSubmit
}: any) => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(loginFormSchema),
    defaultValues: { email: "", password: "", rememberMe: false },
  });
  let navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [submitCliked, setSubmitClicked] = useState<boolean>(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [showResendModal, setShowResendModal] = useState<boolean>(false);
  const [showNotConfirmModal, setShowNotConfirmModal] =
    useState<boolean>(false);
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const profileInfo: any = useSelector((state) => getProfileInfo(state));
  const [ip, setIp] = useState("");
  const profileInfoLoading: any = useSelector((state) =>
    getProfileInfoLoading(state)
  );

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(), // Unique ID for the toast
        message: message,
        type: type, // You can use 'error', 'info', etc.
      })
    );
  };

  const handleResendModal = () => {
    setShowResendModal(true);
    if (linkSent) {
      setLinkSent(false);
    }
  };

  const resendCode = async (email: any) => {
    try {
      const res = await resendConfirmationCode(email || "");
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (data: any) => {
    setSubmitClicked(true);
    localStorage.setItem("rememberMe", data?.rememberMe);

    

    setEmail(data?.email);
    const params: GtagEventParams = {
      event_category: "User",
      event_label: "Login",
      user_email: data?.email,
      event_time: Math.floor(Date.now() / 1000),
      event_source_url: window.location.href,
    };
    if(executeRecaptcha){
      const Token = await executeRecaptcha('Login');
      const loginPayload: Record<string, any> = {
        email: data?.email,
        password: data?.password,
        captchaToken:Token
      };
      try {
     const loginUserData = await loginUserFn(loginPayload);
     gtagEvent('event', 'Login', params);
    //  ReactGA.event({
    //   category: 'User',
    //   action: 'Login',
    // });
      localStorage.setItem("accessToken", loginUserData?.data?.access_token);
      localStorage.setItem("refreshToken", loginUserData?.data?.refresh_token);
      setIsEmailVerified(loginUserData?.data?.isEmailVerified);
      dispatch(
        setAuthGuardToken(decodetoken(loginUserData?.data?.access_token))
      );
      localStorage.setItem("loginType", LOGIN_TYPE.DEFAULT);
      try {
        const Token = await executeRecaptcha("Login");
        const loginPayload: Record<string, any> = {
          email: data?.email,
          password: data?.password,
          captchaToken: Token,
        };
        const loginUserData = await loginUserFn(loginPayload);
        localStorage.setItem("accessToken", loginUserData?.data?.access_token);
        localStorage.setItem(
          "refreshToken",
          loginUserData?.data?.refresh_token
        );
        setIsEmailVerified(loginUserData?.data?.isEmailVerified);
        dispatch(
          setAuthGuardToken(decodetoken(loginUserData?.data?.access_token))
        );
        localStorage.setItem("loginType", LOGIN_TYPE.DEFAULT);
        try {
          dispatch(setProfileInfoLoading(true));
          const { data }: any = await fetchProfileApiFn();
          dispatch(setProfileInfo(data));
          dispatch(setSessionLoading(false));
          if(saveCalDetail){
            calSubmit();
          }
          if (data.organisation.stepsCompleted <= 1) {
            navigate("/onboarding");
          }
          console.log("data", data);
        } catch (error) {
          dispatch(
            addToast({
              id: new Date().getTime(),
              message: (error as any)?.data?.message
                ? (error as any)?.data?.message
                : (error as any)?.message ?? "Something went wrong!",
              type: ToastType.error,
            })
          );
        } finally {
          dispatch(setProfileInfoLoading(false));
          dispatch(setSessionLoading(false));
        }
        handleClose();
        const res = await getIpAddress();
        const device_details = deviceDetect("");
        const payload = {
          device: JSON.stringify(device_details),
          ipAddress: res?.data?.IPv4,
        };
        try {
          await postAuditApiFn(payload);
        } catch (error) {
          console.log("audit api error:", error);
        }
      } catch (error) {
        console.log(
          "Something went wrong while logging in.",
          (error as CustomError)?.response?.data?.message
        );
        if ((error as CustomError)?.response?.data?.isEmailVerified === false) {
          setShowNotConfirmModal(true);
          setIsEmailVerified(
            (error as CustomError)?.response?.data?.isEmailVerified ?? false
          );
        }

        handleShowToast(
          `${
            (error as CustomError)?.response?.data?.message
              ? (error as CustomError)?.response?.data?.message
              : "Something went wrong"
          }`,
          ToastType.error
        );
      } finally {
        setSubmitClicked(false);
      }
      handleClose();
      const res = await getIpAddress();
      const device_details = deviceDetect("");
      const payload = {
        device: JSON.stringify(device_details),
        ipAddress: res?.data?.IPv4,
      };
      try {
        await postAuditApiFn(payload);
      } catch (error) {
        console.log("audit api error:", error);
      }
    } catch (error) {
      console.log(
        "Something went wrong while logging in.",
        (error as CustomError)?.response?.data?.message
      );
      if ((error as CustomError)?.response?.data?.isEmailVerified === false) {
        setShowNotConfirmModal(true);
        setIsEmailVerified(
          (error as CustomError)?.response?.data?.isEmailVerified ?? false
        );
      }

      handleShowToast(
        `${
          (error as CustomError)?.response?.data?.message
            ? (error as CustomError)?.response?.data?.message
            : "Something went wrong"
        }`,
        ToastType.error
      );
    } finally {
      setSubmitClicked(false);
    }}
    else{
      console.error('executeRecaptcha is undefined');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGoogleSignIn = async () => {
    try {
      await GoogleSignIn();
    } catch (err: any) {
      handleShowToast(err?.message ?? "something went wrong", ToastType.error);
    }
  };

  useEffect(() => {
    if (profileInfo?.id && window?.Tawk_API && window?.Tawk_API?.setAttributes) {
      window?.Tawk_API?.setAttributes({
        email: profileInfo?.email,
        user_id: profileInfo?.id,
        user_hash: profileInfo?.crypto_hashId,
      });
    }
  }, [profileInfo]);

  return (
    <>
      {!showNotConfirmModal && (
        <Dialog
          onClose={handleClose}
          open={open}
          className={`stepsDialog loginRegister`}
          data-testid="loginModal"
        >
          <div className="dialogWrapper authStyle">
            <span role="link" className="closeDialog" onClick={handleClose}>
              <Close />
            </span>

            <div className="authStyle__logo flex alignCenter justifyCenter">
              <Logo />
            </div>
            <div className="authStyle__header">
              <h2>Log in to your account</h2>
              <p>Welcome back! Please enter your details.</p>
            </div>

            <form>
              <Input
                type="email"
                label="Email"
                register={register}
                name="email"
                placeholder="Enter your email"
                errors={errors}
                dataTestId={"loginEmailField"}
              ></Input>

              <Input
                type="password"
                label="Password"
                register={register}
                name="password"
                placeholder="Enter your password"
                errors={errors}
                dataTestId={"loginPasswordField"}
              ></Input>

              <div className="authStyle__links dflex justifySpaceBetween alignCenter">
                <FormControlLabel
                  control={<Checkbox {...register("rememberMe")} />}
                  label="Remember for 30 days"
                />
                <span role="link" onClick={forgotpassword}>
                  Forgot password
                </span>
              </div>
              <div className="authStyle__footer" data-testid={"loginSignIn"}>
                <Button
                  label="Sign in"
                  className="primary full xl"
                  action={handleSubmit(onSubmit)}
                  clicked={submitCliked || profileInfoLoading}
                  disabled={submitCliked || profileInfoLoading}
                  type="submit"
                ></Button>

                {/* <Button
                label="Sign in with Google"
                className="outline full xl"
                action={handleGoogleSignIn}
                prefix={<GoogleIcon />}
              ></Button> */}
              </div>
              <div className="authStyle__footerInfo">
                    <p>
              This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy"  target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms"  target="_blank">Terms of Service</a> apply.
              </p>
    </div>
            </form>
          </div>
        </Dialog>
      )}

      {!isEmailVerified && showNotConfirmModal && (
        <NotConfirmedModal
          handleResendModal={handleResendModal}
          setOpen={setShowNotConfirmModal}
          email={email}
          open={showNotConfirmModal}
          showResendModal={showResendModal}
          setShowResendModal={setShowResendModal}
          linkSent={linkSent}
          setLinkSent={setLinkSent}
        />
      )}
    </>
  );
};

export default Login;
