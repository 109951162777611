import React, { useEffect } from "react";
import Input from "../../atoms/Input";
import { useForm } from "react-hook-form";
import Button from "../../atoms/Button";
import { couponSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./CouponCard.module.scss";
import { COUPON_MESSAGES } from "./constants";
import CheckCircle from "../../assets/icons/checkCircle";
import CircleClose from "../../assets/icons/circleClose";
import Close from "../../assets/icons/cross";

const CouponCard = ({
    loading,
    onSubmit,
    couponStatus,
    setCouponStatus,
    handleRemoveCoupon,
    validate,
    couponSelected
}: any) => {
   
    const {
        formState: { errors },
        register,
        handleSubmit,
        setValue,
        watch,
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(couponSchema),
    });

    const codeValue = watch("code");

    const handleRemove = () => {
        setValue("code", "");
        handleRemoveCoupon()
    };

    useEffect(()=>{
        if(couponStatus === "Applied"){
            setValue("code", couponSelected);
        }

    },[couponSelected])
    
    return (
      <div className={` ${styles.coupon}`}>
        <div className={` ${styles.coupon__inner} flex`}>
          <div className={` ${styles.coupon__input}`}>
            <Input
              type="uppercase"
              label="Have a promo Code?"
              register={register}
              name="code"
              placeholder="Enter Coupon Code"
              errors={errors}
              onKeyDown={(e: any) => {
                setCouponStatus("");
              }}
              disabled={couponStatus === "Applied"}
            />
            {couponStatus !== "Applied" && codeValue && (
              <span
                className={` ${styles.coupon__inputClose}`}
                onClick={handleRemove}
                role={loading? "disabled" :"default"}
              >
                <Close />
              </span>
            )}
          </div>
          <div className={` ${styles.coupon__btn}`}>
            {couponStatus !== "Applied" && (
              <Button
                label={"Apply Coupon"}
                className="primary animated full"
                action={handleSubmit(onSubmit)}
                clicked={loading}
                disabled={loading || !codeValue || validate}
              ></Button>
            )}

            {couponStatus === "Applied" && (
              <Button
                label={"Remove"}
                className="primary animated full"
                action={handleRemove}
              ></Button>
            )}
          </div>
        </div>
        <div
          className={`flex alignCenter ${styles.coupon__status} ${
            couponStatus === "Applied" ? styles.applied : ""
          }`}
        >
          {couponStatus !== "" ? (
            couponStatus === "Applied" ? (
              <CheckCircle />
            ) : (
              <CircleClose />
            )
          ) : (
            ""
          )}{" "}
          {COUPON_MESSAGES[couponStatus] || couponStatus}
        </div>
      </div>
    );
};

export default CouponCard;
