import { FormProvider, useForm } from "react-hook-form";
import styles from "./RegisterSteps.module.scss";
import CustomSwitch from "../../atoms/CustomSwitch";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGeneralAccessProduct } from "../../organisms/billing/selectors";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProductType } from "../../organisms/billing/enum";
import { createSubscription } from "../../organisms/billing/billingService";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { getProductByInterval } from "../../organisms/billing/utils";
import AddCard from "../../organisms/billing/AddCard";
import { billingSchema } from "../../organisms/billing/validation";
import Shield from "../../assets/icons/shield";
import { fetchSubscriptions } from "../../organisms/billing/sagaActions";
import { connect } from "socket.io-client";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { fbc, fbp, fetchIp, hashString, hashNumber } from "../../utils/utils";
import { FbConversionApi } from "../../organisms/auth/authServices";
import { validateCoupon } from "../../organisms/creditsSubscription/CreditsSubscriptionService";

const RegisterSteps = ({ nextStep, setPaymentMethod }: any) => {
  const methods = useForm({
    mode: "onSubmit",
    resolver: yupResolver(billingSchema),
  });
  const {
    control,
    watch,
  } = methods;

  const dispatch = useDispatch();
  const [selectedPricing, setSelectedPricing] = useState<any>(null);
  const [confirmCardLoading, setConfirmCardLoading] = useState(false);
  const [discount,setDiscount] = useState(0);
  const generalAccessProducts = useSelector((state) => getGeneralAccessProduct(state));
  const profileData = useSelector((state) => getProfileInfo(state));
  const socketRef = useRef<any>();
  const [validateButtonLoading, setValidateButtonLoading] = useState(false);
  const [couponStatus, setCouponStatus] = useState("");
  const [updatedPricing, setUpdatedPricing] = useState<any>(null);

 const getDiscount = () => {
    const selected :any= getProductByInterval(true, generalAccessProducts?.[0]);
    setDiscount(selected?.discountPercent);
  };
  const payloadData = {
    data: [
      {
        event_name: "Start_trial",
        event_time: Math.floor(Date.now() / 1000),
        event_source_url: window.location.href,
        action_source: "website",
        user_data: {
          client_ip_address: fetchIp,
          client_user_agent: navigator.userAgent,
          fbc: fbc,
          fbp: fbp,
          em: hashString(profileData?.email),
          ph: hashNumber(profileData?.phone),
          external_id: hashNumber(profileData?.organisationId),
        },
      },
    ],
  };

  const onSubmit = async (result: any) => {
    if (result?.setupIntent?.payment_method) {
      try {
        setConfirmCardLoading(true);
        setPaymentMethod(result?.setupIntent?.payment_method ?? "");
        let payload = {
          paymentMethod: result?.setupIntent?.payment_method,
          price: selectedPricing.stripePriceId,
          subsProductType: ProductType.GENERAL_ACCESS,
          trialPeriodDays: selectedPricing.trialPeriodDays,
          ...(updatedPricing && { couponCode: updatedPricing.id }),
        };
        await createSubscription(payload);
        dispatch(fetchSubscriptions({}));
        displaySuccessMessage("Successfully created subscription!");
        nextStep();
      } catch (error: any) {
        displayErrorMessage(error);
        setConfirmCardLoading(false);
      }
    }
  };

  const displayErrorMessage = (error: any) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  };

  const handleCoupon = async (data: any) => {
      try {
        setValidateButtonLoading(true);
        const res = await validateCoupon({
          couponCode: data?.code.toUpperCase(),
          price: selectedPricing.unitAmount,
        });
        setUpdatedPricing(res?.data?.data);
        setValidateButtonLoading(false);
        setCouponStatus("Applied");
      } catch (error:any) {
        setValidateButtonLoading(false);
        setCouponStatus(error?.response?.data?.message);
      }
    };
  
    const handleRemoveCoupon=()=>{
      setCouponStatus("");
      setUpdatedPricing(null)
    }
  


  const displaySuccessMessage = (message: string) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: ToastType.success,
      })
    );
  };

  const fbConversion = async () => {
    try {
      await FbConversionApi(payloadData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (generalAccessProducts.length > 0) {
      const selected = getProductByInterval(
        watch("annualPricing") ?? false,
        generalAccessProducts?.[0]
      );
      setSelectedPricing(selected);
      getDiscount();
    }
  }, [generalAccessProducts, watch("annualPricing")]);

  useEffect(() => {
    socketRef.current = (connect as any)(
      `${process.env.REACT_APP_WS_BASE_URL}/notifications`,
      {
        extraHeaders: {
          "x-user-id": profileData?.id,
        },
      }
    );

    socketRef.current.on("subscription-status", async (data: any) => {
      if (data?.subscriptionStatus === "success") {
        window?.location?.reload();
      }
    });
    return () => {
      socketRef.current.disconnect();
      setTimeout(() => {
        fbConversion();
      }, 1000);
    };
  }, []);

  return (
    <>
      <div className={` ${styles.register}`}>
        <div className="wrapper-md">
          <div className={`dflex alignCenter ${styles.register__info}`}>
            <div className={` ${styles.registerInfo}  `}>
              <p>Try OttoLeads General Access</p>
              <h2>7 days free</h2>
              <p>
                Then ${selectedPricing?.unitAmount} + taxes per{" "}
                {selectedPricing?.interval}
              </p>
              <div className={`cardSwitch flex alignCenter`}>
                <CustomSwitch
                  control={control}
                  name="annualPricing"
                  defaultChecked={false}
                  disabled={couponStatus==="Applied"}
                />
                <div>
                  <h5>
                    Annual pricing <span>(save {discount}%)</span>
                  </h5>
                </div>
              </div>
              <div className="check-list">
                <ul>
                  <li>
                    Advanced property search with over 50 expertly-crafted
                    filters
                  </li>
                  <li>Purchase lists, skip trace, and upload your own data</li>
                  <li>Access to our wholesale and amortization calculators</li>
                  <li>
                    Ability to upgrade to our exclusive AI calling packages
                  </li>
                  <li>
                    Easy to use CRM features to manage your leads and follow ups
                  </li>
                </ul>
              </div>
              <p>AI calling add-on plan available for purchase in the <u>next</u> step.</p>
            </div>
            <div className={` ${styles.formRight}`}>
              <FormProvider {...methods}>
                  <AddCard buttonConfig={{label:"Start your free trial",postfix:<Shield />}} selectedPricing={selectedPricing} displayErrorMessage={displayErrorMessage} confirmCardLoading={confirmCardLoading} setConfirmCardLoading={setConfirmCardLoading} onSubmit={onSubmit}
                      handleCoupon={handleCoupon}
                      validateButtonLoading={validateButtonLoading}
                      couponStatus={couponStatus} setCouponStatus={ setCouponStatus}
                      updatedPricing={updatedPricing}
                      handleRemoveCoupon={handleRemoveCoupon}
                  ></AddCard>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer footerLinks footerWhite /> */}
    </>
  );
};
export default RegisterSteps;
