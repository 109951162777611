import { getAPI, postAPI } from "../../apis/apis";

export const fetchCreditAccessProducts = () => {
  return getAPI({
    url:
      process.env.REACT_APP_BASE_URL +
      `/payment-gateway/credit-access-product`,
    headers: {},
  });
};

export const validateCoupon= (payload:any) => {
  return postAPI({
    url:
      process.env.REACT_APP_BASE_URL +
      `/payment-gateway/validate-coupon`,
    headers: {},
    data:payload
  });
};
